import { useQuery } from "@tanstack/react-query";
import serviceMessageApi from "@/api/serviceMessages/client";

const useServiceMessage = (messageId: string) => {
  const { data } = useQuery({
    queryKey: ["active-service-message", messageId],
    queryFn: serviceMessageApi.getActiveServiceMessage,
  });

  return { message: data };
};

export default useServiceMessage;
