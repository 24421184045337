import config from "@shared/config/client";

const inviteUsers = async (emails: string[]) => {
  const result = await fetch(`${config.apiUrl}/invites`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ emails }),
  });

  return result;
};

const api = {
  inviteUsers,
};

export default api;
