"use client";

import BellIcon from "@/assets/icons/bell.svg";
import { Menu } from "@headlessui/react";
import NotificationPopup from "./NotificationPopup";
import NotificationIndicator from "../NotificationIndicator";
import useGetNotifications from "../hooks/useGetNotifications";
import useGetUnreadNotificationsCount from "../hooks/useGetUnreadNotificationsCount";
import useMe from "@/shared/hooks/useMe";
import { limitTo } from "@/shared/number";
import useMarkNotificationsAsSeen from "../hooks/useMarkNotificationsAsSeen";

const NotificationButton = () => {
  const { data: notifications } = useGetNotifications(5);
  const { me } = useMe();
  const { data: unreadNotificationsCount } = useGetUnreadNotificationsCount({
    userId: me!.id,
  });
  const { mutate: markNotificationsAsSeen } = useMarkNotificationsAsSeen();

  const unreadNotificationIds = notifications
    ?.filter((notification) => !notification.isSeen)
    .map((notification) => notification.id);

  const limitedUnreadNotificationsCount = limitTo(
    9,
    +unreadNotificationsCount!,
  );

  const handleClick = () => {
    if (!unreadNotificationIds?.length) return;

    markNotificationsAsSeen({ notificationIds: unreadNotificationIds });
  };

  return (
    <div className="md:relative">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button
              onClick={handleClick}
              className="flex items-center justify-center w-11 h-11 border-2 border-primary rounded-full relative cursor-pointer"
            >
              {!!limitedUnreadNotificationsCount && (
                <NotificationIndicator position="top-right">
                  <p className="text-white text-xs font-bold">
                    {limitedUnreadNotificationsCount}
                  </p>
                </NotificationIndicator>
              )}
              <BellIcon className="w-6 fill-primary" />
            </Menu.Button>

            <NotificationPopup
              notifications={notifications!}
              hasUnreadNotifications={Boolean(+unreadNotificationsCount!)}
              isOpen={open}
            />
          </>
        )}
      </Menu>
    </div>
  );
};

export default NotificationButton;
