import { useCallback, useEffect } from "react";

/**
 * Hook to detect a specific key press
 *
 * @param key The key to listen for
 * @param onKeyPress The callback to call when the key is pressed
 * @param enable Whether to enable or disable the event listener
 */

export function useKeyPress({
  key,
  onKeyPress,
  enable = false,
}: {
  key: string;
  onKeyPress: () => void;
  enable: boolean;
}): void {
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === key) {
        onKeyPress();
      }
    },
    [key, onKeyPress],
  );

  useEffect(() => {
    if (enable) {
      document.addEventListener("keydown", handleKeyPress);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [enable, handleKeyPress]);
}
