"use client";
import { useSearchParams } from "next/navigation";
import { twMerge } from "tailwind-merge";

export default function SearchLabel() {
  const searchParams = useSearchParams();
  const searchTerm = searchParams.get("searchTerm");

  return (
    <span className={twMerge("text-lg z-20", !searchTerm?.length && "hidden")}>
      Showing results for: <span className="text-red">`{searchTerm}`</span>
    </span>
  );
}
