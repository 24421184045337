import { useField } from "formik";
import { FieldHookConfig } from "formik";
import { ChangeEventHandler, ReactNode } from "react";
import { Input } from "../Input";

export interface Props {
  label: string | ReactNode;
  type: string;
  tooltipText?: string;
  className?: string;
  removeTooltipOnDesktop?: boolean;
  alignCenter?: boolean;
  shortInfo?: string;
  allThemesWithWhiteBackground?: boolean;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  min?: number;
  step?: string;
  validateOnMount?: boolean;
  labelClassName?: string;
  warningMsg?: string;
  tooltipIconClassName?: string;
  endInfo?: string;
  required?: boolean;
}
// TODO: colors are hardcoded
const FormInput = ({
  label,
  type,
  tooltipText,
  className,
  autoComplete,
  removeTooltipOnDesktop,
  alignCenter = true,
  shortInfo,
  allThemesWithWhiteBackground,
  placeholder,
  onChange,
  min,
  step,
  disabled,
  validateOnMount,
  labelClassName,
  warningMsg,
  tooltipIconClassName,
  endInfo,
  required,
  ...props
}: Props & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);

  const isError = (validateOnMount || !!meta.touched) && !!meta.error;
  const isWarning = (validateOnMount || !!meta.touched) && !!warningMsg;

  return (
    <Input
      label={label}
      type={type}
      isError={isError}
      errorMsg={meta.error}
      autoComplete={autoComplete}
      field={field}
      tooltipText={tooltipText}
      className={className}
      removeTooltipOnDesktop={removeTooltipOnDesktop}
      alignCenter={alignCenter}
      shortInfo={shortInfo}
      allThemesWithWhiteBackground={allThemesWithWhiteBackground}
      placeholder={placeholder}
      onChange={onChange}
      min={min}
      step={step}
      disabled={disabled}
      labelClassName={labelClassName}
      isWarning={isWarning}
      warningMsg={warningMsg}
      tooltipIconClassName={tooltipIconClassName}
      endInfo={endInfo}
      required={required}
    />
  );
};

export default FormInput;
