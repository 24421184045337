"use client";
import { Button } from "@/app/(components)/Basic/Button";
import { ThemedIcon } from "@/app/(components)/Basic/Icon";
import { Modal } from "@/app/(components)/Basic/Modal";
import { SuccessData } from "./types";
import { ImageTypeHandler } from "../ImageTypeHandler";
export interface Props extends SuccessData {
  onClose?: () => void;
  isOpen: boolean;
}

export default function SuccessModal({
  item,
  text,
  redirectUrl,
  buttonText,
  onClose,
  title = "",
  isOpen,
}: Props) {
  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <Modal
      hasCloseButton
      className="flex flex-col items-center gap-y-6 lg:min-w-[510px]"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <div className="text-h2-sm">{title}</div>
      <div className="text-body-2">{text}</div>
      <div className="relative max-w-[250px]">
        <ImageTypeHandler
          src={item.media}
          type={item.mediaType}
          width={item.mediaWidth || 240}
          height={item.mediaHeight || 240}
          placeholder={item.placeholder}
        />
        <div className="absolute -top-5 left-0 w-full h-full flex justify-center">
          <ThemedIcon className="w-12 h-12" iconName="checkmark" />
        </div>
      </div>
      <div className="text-body-2 font-medium">{item?.name}</div>
      {buttonText ? (
        <Button href={redirectUrl} onClick={handleClose}>
          {buttonText}
        </Button>
      ) : null}
    </Modal>
  );
}
