import { SearchCategory } from "@/api/search/types";
import { PropsWithChildren } from "react";
import { MenuItem, Navigation } from "../../Layout/Navigation";
import SearchMenuItem from "./SearchMenuItem";

const menuItems: MenuItem[] = [
  {
    name: <SearchMenuItem id={SearchCategory.Collections} name="Collections" />,
    href: `/search/collections`,
  },
  {
    name: <SearchMenuItem id={SearchCategory.Artists} name="Artists" />,
    href: `/search/artists`,
  },
  {
    name: <SearchMenuItem id={SearchCategory.Artwork} name="Artwork" />,
    href: `/search/artwork`,
  },
];

function SearchPageWrapper({ children }: PropsWithChildren) {
  return (
    <>
      <div className="flex items-center justify-center">
        <Navigation
          menuItems={menuItems}
          shouldHideActiveIndicatorOnScroll={false}
          minimizeOnScroll={true}
          className="lg:-bottom-2"
          preserveSearchQuery
        />
      </div>
      {children}
    </>
  );
}

export default SearchPageWrapper;
