"use client";
import React, { useEffect, useRef, useState, useTransition } from "react";
import SearchIcon from "@/assets/icons/search.svg";
import { twMerge } from "tailwind-merge";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useKeyPress } from "@/shared/hooks/useKeyPress";
import useOutsideClick from "@/shared/hooks/useOutsideClick";
import { SubmitType } from "./types";

export interface Props {
  onSubmit?: () => void;
  initialValue?: string;
  submitType?: SubmitType;
  expandedByDefault?: boolean;
  className?: string;
}

const SearchInput = ({
  onSubmit = () => {},
  expandedByDefault,
  submitType = SubmitType.Submit,
  className,
}: Props) => {
  const searchParams = useSearchParams();
  const searchTerm = searchParams.get("searchTerm");
  const router = useRouter();
  const pathname = usePathname();
  const [expanded, setExpanded] = useState(expandedByDefault);
  const [, startTransition] = useTransition();
  const ref = useRef<HTMLInputElement>(null);

  const searchRef = useOutsideClick<HTMLDivElement>({
    handler: () => setExpanded((prevState) => !prevState),
    enabled: !!expanded,
  });

  useKeyPress({
    key: "Escape",
    onKeyPress: () => setExpanded((prevState) => !prevState),
    enable: !!expanded,
  });

  const [search, setSearch] = useState(searchTerm);

  useEffect(() => {
    setSearch(searchTerm);
  }, [searchTerm, pathname]);

  const handleSubmit = () => {
    onSubmit();
    setExpanded(!expanded);
    if (submitType === SubmitType.Submit && !pathname.includes("search")) {
      router.push(`/search?searchTerm=${search}`);
    } else {
      router.push(`${pathname}?searchTerm=${search}`);
    }
    setSearch("");
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
    if (submitType === SubmitType.Submit) return;
    startTransition(() => {
      router.replace(`${pathname}?searchTerm=${value}`);
    });
  };

  const handleClick = () => {
    if (!search) {
      setExpanded(!expanded);
      ref.current!.focus();
    } else {
      handleSubmit();
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && search) {
      handleSubmit();
    }
  };

  return (
    <div
      ref={searchRef}
      className={twMerge(
        "transition-all duration-400 ease-in-out box-border border-2 border-primary border-solid rounded-full h-11 py-[2px] px-1 lg:absolute lg:right-0 flex items-center light:bg-primary night:bg-primary",
        expandedByDefault || expanded
          ? "lg:endemic:bg-primary lg:endemic:border-invert"
          : "",
        className,
      )}
    >
      <input
        type="text"
        ref={ref}
        value={search || ""}
        className={twMerge(
          "border-none outline-none text-base transition-all duration-200 ease-in-out bg-transparent text-primary lg:endemic:text-invert ml-2",
          expandedByDefault || expanded
            ? "px-2  w-[208px] lg:w-[280px]"
            : "sm:w-0 sm:ml-0",
        )}
        onChange={(e) => {
          handleChange(e);
        }}
        onKeyDown={handleKeyDown}
        placeholder="Search"
      />
      <div
        className="flex justify-center items-center w-8 h-8 cursor-pointer"
        onClick={handleClick}
        role="button"
      >
        <SearchIcon
          className={twMerge(
            "fill-primary",
            expandedByDefault || expanded ? "lg:endemic:fill-invert" : "",
          )}
        />
      </div>
    </div>
  );
};

export default SearchInput;
