import { Menu } from "@headlessui/react";
import styles from "./NotificationPopup.module.css";
import useMe from "@/shared/hooks/useMe";
import { NoItems } from "@/app/(components)/Basic/NoItems";
import NotificationPopupList from "../NotificationPopupList";
import useMarkAllNotificationsAsSeen from "../../hooks/useMarkAllNotificationsAsSeen";
import { Notification } from "@/api/notification/types";
import CloseIcon from "@/assets/icons/close-thin.svg";
import { SizesValues } from "@/shared/constants";
import useDisableScroll from "@/shared/hooks/useDisableScroll";

interface Props {
  notifications: Notification[];
  hasUnreadNotifications: boolean;
  isOpen: boolean;
}

const NotificationPopup = ({
  notifications,
  hasUnreadNotifications,
  isOpen,
}: Props) => {
  const { me } = useMe();
  const { mutate: markAllNotificationsAsSeen } = useMarkAllNotificationsAsSeen({
    userId: me!.id,
  });

  useDisableScroll({ disable: isOpen, breakpoint: SizesValues.MD });

  return (
    <Menu.Items className="absolute z-10 w-screen h-screen top-0 right-0 md:top-[56px] md:-right-2 lg:-right-15 md:rounded-[20px] md:border-2 md:border-primary md:w-80 md:h-auto cursor-auto select-none bg-primary p-6 md:p-0">
      <div className={styles.triangle} />

      <div className="flex items-center justify-between md:px-4 md:py-4 border-b-[1px] border-endemicGray-500 pb-5">
        <p className="text-primary text-base/[16px] endemic:text-invert">
          Notifications
        </p>
        <Menu.Item>
          {({ close }) => (
            <div onClick={close}>
              <CloseIcon className="h-4 w-4 fill-endemicGray-700 md:hidden hover:cursor-pointer" />
            </div>
          )}
        </Menu.Item>
        {hasUnreadNotifications && (
          <p
            className="text-red text-sm/[14px] hidden md:block cursor-pointer"
            onClick={() => markAllNotificationsAsSeen()}
          >
            Mark all as read
          </p>
        )}
      </div>
      {!!notifications?.length ? (
        <>
          <NotificationPopupList notifications={notifications} />
          <div className="py-6 md:py-4 flex md:justify-center items-center justify-between">
            {hasUnreadNotifications && (
              <p
                className="text-red text-sm/[14px] md:hidden cursor-pointer"
                onClick={() => markAllNotificationsAsSeen()}
              >
                Mark all as read
              </p>
            )}
            <a
              href={`/${me!.id}/notifications`}
              className="text-red text-sm/[14px] cursor-pointer ml-auto md:ml-0"
            >
              View all
            </a>
          </div>
        </>
      ) : (
        <NoItems message="No items to display." />
      )}
    </Menu.Items>
  );
};

export default NotificationPopup;
