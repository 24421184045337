import { Menu } from "@headlessui/react";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import styles from "./DropdownItems.module.css";
import { twMerge } from "tailwind-merge";
import { getUsersHref, getUsersName } from "@/api/users/helpers";
import useLogout from "../../../Login/hooks/useLogout";
import { User, UserRoles } from "@/api/users/types";
import ProfileIcon from "@/assets/icons/profile.svg";
import InviteIcon from "@/assets/icons/invite.svg";
import ApplyIcon from "@/assets/icons/apply.svg";
import SettingsIcon from "@/assets/icons/settings.svg";
import LogoutIcon from "@/assets/icons/logout.svg";
import ArrowRightIcon from "@/assets/icons/arrow-right.svg";
import CloseIcon from "@/assets/icons/close-thin.svg";
import useDisableScroll from "@/shared/hooks/useDisableScroll";
import { SizesValues } from "@/shared/constants";
import { Button } from "@/app/(components)/Basic/Button";
import { UserImageWithLevelChart } from "@/app/(components)/Basic/UserImageWithLevelChart";
import { getPointsLeftToFinishCurrentLevel } from "@/shared/helpers";
import ReferralModal from "../../ReferralModal/ReferralModal";

interface Props {
  isOpen: boolean;
  user: User;
}

const DropdownItems = ({ user, isOpen }: Props) => {
  const logout = useLogout(() => window.location.reload());

  const [isInviteOpen, setIsInviteOpen] = useState(false);

  const onLogout = useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();

      await logout();
    },
    [logout],
  );

  const userProfileHref = getUsersHref(user);
  const links = useMemo(
    () => [
      {
        href: userProfileHref,
        label: "View profile",
        icon: <ProfileIcon />,
      },
      ...(user.endemicIdentityTokenId
        ? [
            {
              href: "#",
              label: "Invite others",
              icon: <InviteIcon className="fill-endemicGray-700" />,
              onClick: () => setIsInviteOpen(true),
            },
          ]
        : []),
      ...(user.role !== UserRoles.ARTIST
        ? [
            {
              href: "/apply/artist",
              label: "Apply",
              icon: <ApplyIcon className="fill-endemicGray-700" />,
            },
          ]
        : []),
      { href: "/settings", label: "Settings", icon: <SettingsIcon /> },

      {
        href: "#",
        label: "Log Out",
        icon: <LogoutIcon />,
        onClick: onLogout,
      },
    ],
    [onLogout, userProfileHref, user.role],
  );

  useDisableScroll({ disable: isOpen, breakpoint: SizesValues.LG });

  return (
    <>
      {user.address && (
        <ReferralModal
          isOpen={isInviteOpen}
          setIsOpen={setIsInviteOpen}
          referralCode={user.address}
        />
      )}

      <Menu.Items className="absolute bg-primary lg:top-14 lg:-left-[220px] flex flex-col lg:w-[320px] p-6 py-3 lg:border-2 lg:border-primary lg:rounded-[20px] w-screen h-screen lg:h-auto  top-0 left-0">
        <div className={styles.triangle} />
        <div>
          <Menu.Item>
            {({ close }) => (
              <div onClick={close}>
                <CloseIcon className="ml-auto h-4 w-4 fill-endemicGray-700 lg:hidden hover:cursor-pointer" />
              </div>
            )}
          </Menu.Item>
          <div className="text-xl text-center font-light endemic:text-invert overflow-hidden overflow-ellipsis whitespace-nowrap">
            {getUsersName(user, true)}
          </div>
          <div className="text-body-4 text-secondary endemic:text-endemicGray-700 text-center overflow-hidden overflow-ellipsis whitespace-nowrap">
            {user.displayName || ""}
          </div>

          <div className="flex flex-col gap-y-4 items-center my-4">
            <UserImageWithLevelChart
              score={user.points!}
              level={user.level!}
              imageName={getUsersName(user)}
              imagePlaceholder={user.imagePlaceholder}
              imageClassName="w-20 h-20"
              image={{
                src: user.imageUrl,
              }}
            />
            <div className="text-[13px] font-light endemic:text-invert">
              Next level in:{" "}
              {getPointsLeftToFinishCurrentLevel(user.level!, user.points!)} XP
            </div>
            <Menu.Item>
              {({ close }) => (
                <Button
                  type="link"
                  href="/quests"
                  endIcon={<ArrowRightIcon className="w-4" />}
                  onClick={close}
                >
                  Level up now
                </Button>
              )}
            </Menu.Item>
          </div>
        </div>
        {links.map(({ href, icon, label, onClick }, index) => (
          <Menu.Item key={index} as={Fragment}>
            {({ active }) => (
              <a
                onClick={onClick}
                href={href}
                className={twMerge(
                  "flex py-4 px-3 gap-4 rounded-lg shadow-none border-none items-center",
                  `${active ? "bg-gray-100 night:bg-[#2a2a2a]" : ""}`,
                )}
              >
                <span className="w-6 h-6 flex-[0_0_auto]">{icon}</span>
                <span className="flex-1 text-body-2 endemic:text-invert">
                  {label}
                </span>
                <ArrowRightIcon className="flex-[0_0_auto] stroke-secondary fill-secondary w-4 endemic:fill-endemicGray-700 endemic:stroke-endemicGray-700" />
              </a>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </>
  );
};

export default DropdownItems;
