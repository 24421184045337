"use client";
import React, { Fragment, useState } from "react";
import { usePathname } from "next/navigation";
import { Theme } from "@/shared/constants";
import { ThemeSelector } from "@/app/(components)/Basic/ThemeSelector";
import { twMerge } from "tailwind-merge";
import { Disclosure } from "@headlessui/react";
import ChevronIcon from "@/assets/icons/chevron.svg";
import CloseIcon from "@/assets/icons/close.svg";
import Link from "next/link";
import { LogoDetailed } from "@/app/(components)/Basic/LogoDetailed";
import { BlockchainSwitcher } from "@/app/(components)/Basic/BlockchainSwitcher";
import MenuIcon from "@/assets/icons/menu.svg";
import { SearchInput } from "../../Application/Search";
import { ThemedIcon } from "../../Basic/Icon";

export interface Props {
  theme?: Theme;
  menuItems: Array<any>;
}
function MobileSidebar({ theme, menuItems }: Props) {
  const pathname = usePathname();
  const [displayMobileMenu, setDisplayMobileMenu] = useState(false);

  const toggleSidebar = () => {
    setDisplayMobileMenu(!displayMobileMenu);

    document.body.style.overflow = displayMobileMenu ? "unset" : "hidden";
  };

  return (
    <div className="flex lg:hidden">
      <button
        type="button"
        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
        onClick={() => toggleSidebar()}
      >
        <MenuIcon className="fill-primary" />
      </button>
      {displayMobileMenu && (
        <div className="navigation fixed inset-y-0 right-0 z-10 w-full overflow-y-auto px-6 py-6 bg-primary">
          <div className="flex fixed">
            <button
              type="button"
              className="-m-2.5 rounded-md p-1"
              onClick={() => toggleSidebar()}
            >
              <CloseIcon className="fill-primary" />
            </button>
          </div>
          <div className="flex flex-col items-center justify-center">
            <span className="bt-16">
              <ThemeSelector theme={theme} />
            </span>
            <span className="relative pt-10">
              <LogoDetailed alt="logo" width={186} height={120} />
            </span>
            <span className="pt-10">
              <SearchInput onSubmit={toggleSidebar} expandedByDefault={true} />
            </span>
            <span className="pt-10">
              <BlockchainSwitcher toggleMobileSidebar={toggleSidebar} />
            </span>

            <div className="space-y-2 py-6 -mx-3 w-full max-w-[265px]">
              {menuItems.map((menuItem: any) => {
                return (
                  <Fragment key={menuItem.name}>
                    {menuItem.subMenu ? (
                      <Disclosure as="div">
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="justify-between flex w-full items-center py-2 leading-7 text-2xl">
                              {menuItem.subMenu?.some(
                                (subMenuItem: any) =>
                                  subMenuItem.href == pathname,
                              ) ? (
                                <div className="text-red endemic:text-yellow font-bold">
                                  {menuItem.name}
                                </div>
                              ) : (
                                <div className="font-normal">
                                  {menuItem.name}
                                </div>
                              )}
                              <span>
                                <ChevronIcon
                                  className={twMerge(
                                    open ? "rotate-180" : "",
                                    "fill-primary w-4",
                                  )}
                                  aria-hidden="true"
                                />
                              </span>
                            </Disclosure.Button>
                            <Disclosure.Panel className="mt-2 space-y-2">
                              {() => (
                                <div>
                                  {menuItem.subMenu.map((item: any) => {
                                    return (
                                      <Link
                                        key={item.name}
                                        href={item.href}
                                        className="block py-2 text-sm "
                                        onClick={() =>
                                          pathname !== item.href &&
                                          toggleSidebar()
                                        }
                                      >
                                        <span className="flex items-center">
                                          <span className="mr-3 w-12 min-w-[48px] h-12 max-h-12 flex justify-center items-center">
                                            {item.iconNameOnMobile ? (
                                              <ThemedIcon
                                                iconName={item.iconNameOnMobile}
                                                className="w-12 h-12"
                                              />
                                            ) : (
                                              item.image
                                            )}
                                          </span>
                                          <span className="flex flex-col">
                                            <span className="mb-1 font-normal text-base">
                                              {item.name}
                                            </span>
                                            <span className="text-body-4 font-light -tracking-wide">
                                              {item.description}
                                            </span>
                                          </span>
                                        </span>
                                      </Link>
                                    );
                                  })}
                                </div>
                              )}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ) : (
                      <Link
                        href={menuItem.href}
                        key={menuItem.name}
                        onClick={() => toggleSidebar()}
                        className={twMerge(
                          "block rounded-lg py-2 leading-7 text-2xl",
                          menuItem.href === pathname
                            ? "text-red endemic:text-yellow font-bold"
                            : "font-normal",
                        )}
                      >
                        {menuItem.name}
                      </Link>
                    )}
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MobileSidebar;
