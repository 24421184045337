"use client";

import {
  ChangeEventHandler,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useState,
} from "react";
import EyeOpenedIcon from "@/assets/icons/eye-opened.svg";
import EyeClosedIcon from "@/assets/icons/eye-closed.svg";
import { twMerge } from "tailwind-merge";
import InfoIcon from "@/assets/icons/info.svg";
import { Tooltip } from "../Tooltip";
import { FieldInputProps } from "formik";

export interface Props {
  type?: string;
  label?: string | ReactNode;
  labelClassName?: string;
  isError?: boolean;
  errorMsg?: string;
  isWarning?: boolean;
  warningMsg?: string;
  field?: FieldInputProps<string>;
  tooltipText?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  className?: string;
  removeTooltipOnDesktop?: boolean;
  alignCenter?: boolean;
  shortInfo?: string;
  allThemesWithWhiteBackground?: boolean;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  min?: number;
  step?: string;
  tooltipIconClassName?: string;
  endInfo?: string;
  required?: boolean;
}
// TODO: colors are hardcoded
const Input = (
  {
    label,
    type,
    isError,
    errorMsg,
    isWarning,
    warningMsg,
    field,
    tooltipText,
    endIcon,
    className,
    removeTooltipOnDesktop,
    alignCenter = true,
    shortInfo,
    allThemesWithWhiteBackground,
    labelClassName,
    placeholder,
    onChange,
    min,
    step,
    tooltipIconClassName,
    endInfo,
    required,
    ...props
  }: Props & InputHTMLAttributes<HTMLInputElement>,
  ref: ForwardedRef<any>,
) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const inputValue = field
    ? field.value !== null && field.value !== undefined
      ? field.value
      : ""
    : props.value || "";

  const numberInputOnWheelPreventChange = (e: any) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <div
      className={`flex flex-col w-full ${alignCenter ? "items-center" : ""}`}
    >
      {tooltipText && (
        <div className="relative">
          <Tooltip
            text={tooltipText}
            className="lg:hidden absolute top-0 right-4"
            tooltipClassName="w-max"
            position={["left", "top"]}
          >
            <InfoIcon className="w-[18px] h-[18px] fill-secondary" />
          </Tooltip>
        </div>
      )}
      {(label || shortInfo) && (
        <span className="flex justify-between">
          {label && (
            <label
              className={twMerge(
                "flex flex-row justify-between w-full",
                "text-sm font-light tracking-tight px-4",
                allThemesWithWhiteBackground
                  ? "text-endemicGray"
                  : "text-primary",
                labelClassName,
              )}
              htmlFor={props.id || props.name}
            >
              <span>
                {label} {required && <span className="text-red">*</span>}
              </span>
              {endIcon}
            </label>
          )}
          {shortInfo && (
            <span
              className={twMerge(
                tooltipText ? "mr-20" : "mr-4",
                "text-body-4 whitespace-nowrap flex items-center",
              )}
            >
              <span
                className={
                  allThemesWithWhiteBackground
                    ? "text-secondary endemic:text-endemicGray-700"
                    : "text-primary"
                }
              >
                {shortInfo}
              </span>
            </span>
          )}
        </span>
      )}
      <div className="w-full flex items-center">
        <div className="relative flex w-full items-center">
          <input
            lang="en"
            ref={ref}
            className={twMerge(
              "w-full text-primary outline-none caret-green h-[40px] rounded-[20px] border-2 px-4 text-base font-light mt-[10px] mb-2 bg-transparent endemic:text-white placeholder:text-secondary",
              props.disabled && "bg-grey-200 night:opacity-30",
              `${
                isError
                  ? "border-red endemic:border-yellow"
                  : "border-endemicGray-300 focus:border-green night:border-endemicGray-900 night:focus:border-green  endemic:focus:border-white"
              } ${alignCenter ? "text-center" : ""} ${className}`,
            )}
            {...props}
            {...field}
            onWheel={numberInputOnWheelPreventChange}
            value={inputValue}
            onChange={(e) => {
              field?.onChange(e);
              onChange?.(e);
            }}
            min={min}
            step={step}
            type={showPassword ? "text" : type}
            placeholder={placeholder && placeholder}
          />
          {type === "password" && (
            <button type="button" onClick={toggleShowPassword}>
              {showPassword ? (
                <EyeClosedIcon className="absolute top-[19px] right-[10px] w-6 h-6 fill-endemicGray-500 night:fill-endemicGray-300 endemic:fill-white" />
              ) : (
                <EyeOpenedIcon className="absolute top-[19px] right-[10px] w-6 h-6 fill-endemicGray-500 night:fill-endemicGray-300 endemic:fill-white" />
              )}
            </button>
          )}
          {endInfo && (
            <span className="absolute top-[19px] right-6 text-body-3">
              {endInfo}
            </span>
          )}
        </div>
        {!removeTooltipOnDesktop && tooltipText && (
          <Tooltip
            text={tooltipText}
            tooltipClassName="w-max"
            className="hidden lg:inline"
            position={["center", "top"]}
          >
            <div className={twMerge("ml-10", tooltipIconClassName)}>
              <InfoIcon
                className={twMerge(
                  "w-[18px] h-[18px] fill-secondary",
                  allThemesWithWhiteBackground ? "" : "endemic:fill-primary",
                )}
              />
            </div>
          </Tooltip>
        )}
      </div>
      <span className="text-red font-semibold text-xs px-4 h-[14px] endemic:text-yellow">
        {isError ? errorMsg : ""}
      </span>
      <span className="text-yellow endemic:text-white font-semibold text-xs px-4 h-[14px]">
        {!!isWarning && !isError ? warningMsg : ""}
      </span>
    </div>
  );
};

export default forwardRef(Input);
