import { Modal } from "@/app/(components)/Basic/Modal";
import { handleCopyText } from "@/shared/utilities";
import { useState } from "react";
import config from "@/shared/config/client";
import { Button } from "@/app/(components)/Basic/Button";
import { Form, Formik } from "formik";
import { FormInput } from "@/app/(components)/Basic/FormInput";
import * as Yup from "yup";
import invitesApi from "@/api/invites";
import { twJoin } from "tailwind-merge";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  referralCode: string;
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const ReferralModal = ({ isOpen, setIsOpen, referralCode }: Props) => {
  const [disabled, setDisabled] = useState(false);
  const copyReferralLink = () => {
    if (disabled) return;
    handleCopyText(
      `${config.url}/login?referralCode=${referralCode}`,
      setDisabled,
    );
  };

  const validationSchema = Yup.object().shape({
    emails: Yup.string()
      .required("Enter a valid comma-separated list of email addresses")
      .test(
        "test-emails",
        "Enter a valid comma-separated list of email addresses",
        (value: string) => {
          const emails = value.split(",");
          return emails.every((email) => emailRegex.test(email.trim()));
        },
      ),
  });

  const onSubmit = async (values: { emails: string }) => {
    const emails = values.emails.split(",");
    await invitesApi.inviteUsers(emails);
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <h3 className="mb-6">Invite others</h3>
      <div className="text-body-3 mb-6">
        Use this form or copy and send link to others. If they create account
        <br />
        with your link you will earn 30000 points.
      </div>

      <Formik
        validationSchema={validationSchema}
        initialValues={{
          emails: "",
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        {() => (
          <Form>
            <FormInput
              label=""
              type="text"
              name="emails"
              placeholder="Enter comma separated email values"
              alignCenter={false}
            />
            <div className="flex justify-between items-center">
              <a
                className={twJoin(
                  "underline text-red text-body-2",
                  disabled
                    ? "opacity-50 pointer-events-none"
                    : "cursor-pointer",
                )}
                onClick={copyReferralLink}
              >
                {!disabled ? "Copy link" : "Copied"}
              </a>
              <Button type="submit">Invite</Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ReferralModal;
