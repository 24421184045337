import { useWeb3State, useWeb3Actions } from "@/web3/context";
import { Button } from "../Button";
import { Modal } from "../Modal";
import GreenEndyIcon from "@/assets/icons/endy/endy-green.svg";

export default function NetworkMismatchPopup() {
  const { isAccountChangedPopupVisible } = useWeb3State();

  const { hideAccountChangePopup } = useWeb3Actions();

  const onClose = () => {
    hideAccountChangePopup();
  };

  return (
    <Modal isOpen={isAccountChangedPopupVisible} onClose={onClose}>
      <div className="flex flex-col items-center justify-center gap-y-10">
        <GreenEndyIcon className="w-20 h-20  night:fill-white fill-black" />
        <div className="text-h3 text-center whitespace-nowrap">
          You changed account.
        </div>
        <div className="text-body-2 text-center whitespace-nowrap">
          This means you should login again with your new account.
        </div>
        <div className="flex flex-col gap-y-2 items-center">
          <Button
            onClick={async () => {
              window.location.pathname = "/login";
              hideAccountChangePopup();
            }}
          >
            Login
          </Button>
        </div>
      </div>
    </Modal>
  );
}
