"use client";
import { Count, SearchCategory } from "@/api/search/types";
import { useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "next/navigation";
import { getSearchCountQueryKey } from "./helpers";

interface Props {
  id: SearchCategory;
  name: string;
}

export default function SearchMenuItem({ id, name }: Props) {
  const searchParams = useSearchParams();
  const searchTerm = searchParams.get("searchTerm");
  const queryClient = useQueryClient();

  const data = queryClient.getQueryData<Count>(
    getSearchCountQueryKey(searchTerm || ""),
  );
  return <>{`${name} (${data ? data[id] : "-"})`}</>;
}
